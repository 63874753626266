export const insuranceTypeEnum = {
  Medical: "medical",
  Life: "life",
  Motor: "motor",
  Travel: "travel",
  Home: "home",
  SMEMedical: "groupMedical",
  MedicalInsuranceId: 5,
  LifeInsuranceId: 0,
  MotorInsuranceId: 6,
  TravelInsuranceId: 2,
  HomeInsuranceId: 3,
  SMEMedicalInsuranceId: 20,
  LifeInsuranceSteps: 4,
  MedicalInsuranceSteps: 4,
  MotorInsuranceSteps: 7,
  TravelInsuranceSteps: 6,
  HomeInsuranceSteps: 8,
  SMEMedicalInsuranceSteps: 5,
  Choice: 'choice',
  App: 'is_app=true',
  InternationalMedical: "internationalMedical",
  InternationalMedicalId: 18,
  InternationalMedicalSteps: 5,
  RemoveLayout: "is_header=false",
  ProfessionalLiabilityInsuranceId: 14,
  ProfessionalLiabilityInsurance: "pirofessionalLiabilityInsurance",
  SuretyandFidelityInsuranceId: 15,
  SuretyAndFidelityInsurance: "suretyAndFidelityInsurance",
  FireInsuranceId: 8,
  FireInsurance: "fireInsurance",
  FreightInsuranceId: 10,
  FreightInsurance: "freight",
  CorpLifeInsuranceId: 21,
  CorpLifeInsurance: "corpLife",
  CorpMotorInsuranceId: 22,
  CorpMotorInsurance: "corpMotor"
};
export const genderEnum = {
  Male: "M",
  Female: "F"
}
export const homeTypeEnum = {
  Owner: "owner",
  Tenant: "tenant"
}
export const mongezPromo = {
  PromoValue: ["y8qz7j", "u4f0r1"],
  PromoDeadLine: "10/10/2021"
}
export const paymentTypeEnum = {
  FullAmount: "full Amount",
  Installments: "instalments"
}
export const paymentMethodsEnum = {
  Card: "CARD",
  Kiosk: "KIOSK",
  Wallet: "WALLET",
  Cash: "CASH",
  Valu: "VALU",
  CARD_INSTALMENT: "CARD_INSTALMENT"
}
export const postPayEnum = {
  True: "true",
  Success: "success",
}
export const languageEnum = {
  English: "en",
  Arabic: 'ar',
}
export const isChronicIllnessEnum = {
  True: true,
  False: false,
}
export const amenliURLS = {
  Website: "https://www.amenli.com/#/",
  Facebook: "https://www.facebook.com/amenli.insurance/?tsid=0.33475223071972016&source=result",
  Instagram: "https://instagram.com/amenli.insurance?utm_medium=copy_link",
  Linkedin: "https://www.linkedin.com/company/amenli/"
}
export const benefitsThreshold = 5

export const policySteps = {
  "medical": 4,
  "life": 4,
  "motor": 8,
  "travel": 6,
  "home": 6,
  "groupMedical": 5,
  "internationalMedical": 5,
  "pirofessionalLiabilityInsurance": 3,
  "suretyAndFidelityInsurance": 3,
  "fireInsurance": 3,
  "freight": 3,
  "corpLife": 3,
  "corpMotor": 3
}

export const vodafoneCashPromo = 'vf4957'
export const cartonaPromo = '8r6nd2'
export const freeHomeInsurancePromo = 'npwukk'
export const EtisalatHost = ["etisalat-stage.amenli.com", "etisalat.amenli.com", "etisalat-dev.amenli.com"]
export const etisalatHost = EtisalatHost.includes(window.location.hostname)
export const noRecommendationsTypes = [0, 18]

export const amenliProducts = [
  { product: "medical insurance", type: "medical" },
  { product: "life insurance", type: "life" },
  { product: "motor insurance", type: "motor" },
  { product: "travel insurance", type: "travel" },
  { product: "home insurance", type: "home" },
  { product: "business insurance", type: "groupMedical" },
  { product: "international medical insurance", type: "internationalMedical" }
]
export const mixpanelProducts = [5, 6, 18, 20, 3, "medical", "motor", "internationalMedical", "groupMedical", "home"]
export const TrackingEvents = {
  //QE HOME PAGE
  SELECT_PRODUCT: "select_prodcut",
  CHANGE_LANGUGAE: "change_language",
  AMENLI_LOGO_CLICKED: "amenli_logo_clicked",
  QE_VIEWED: "qe_viewed",
  CLICK_RELOAD: "click_reload",
  //MEDICAL STEP ONE
  CLICK_DATE_FIELD: "click_date_field",
  DATE_CLICK_NEXT: "date_click_next",
  QE_MEDICAL_STEP1_VIEWED: "qe_medical_step1_viewed",
  CLICK_INSURANCE_COMPANY: "click_insurance_company",
  //MEDICAL STEP TWO
  SELECT_GENDER: "select_gender",
  QE_MEDICAL_STEP2_VIEWED: "qe_medical_step2_viewed",
  //MEDICAL STEP THREE
  CLICK_USER_NAME_FIELD: "click_user_name_field",
  QE_MEDICAL_STEP3_VIEWED: "qe_medical_step3_viewed",
  USERNAME_CLICK_NEXT: "username_click_next",
  //MEDICAL STEP FOUR
  CLICK_PHONE_NUMBER_FIELD: "click_phonenumber_field",
  PHONE_NUMBER_CLICK_NEXT: "phonenumber_click_next",
  QE_MEDICAL_STEP4_VIEWED: "qe_medical_step4_viewed",
  //MEDICAL LOADING RESULTS
  QE_MEDICAL_STEP5_VIEWED: "qe_medical_step5_viewed",
  LOADING_POLICIES_RUNNING: "loading_policies_running",
  LOADING_POLICIES_STOPPED: "loading_policies_stopped",
  CLICK_MEDICAL_NETWORK: "click_medical_network",
  CLICK_BENEFIT_TABLE: "click_benefit_table",
  CLICK_SEE_MORE: "click_see_more",
  CLICK_SEE_LESS: "click_see_less",
  CLICK_CHOOSE_POLICY: "click_choose_policy",
  //QE MEDICAL THANK YOU PAGE
  THANKYOU_VIEWED: "thankyou_viewed",
  CLICK_PROCEED_TO_PAYMENT: "click_proceed_to_payment",
  CLICK_RETURN_HOME: "click_return_home",
  //QE MEDICAL CHECKOUT
  CHECKOUT_VIEWED: "checkout_viewed",
  SELECT_PAYMENT_PREFRENCE: "select_payment_prefrence",
  SELECT_PAYMENT_METHOD: "select_payment_method",
  CLICK_CHECKOUT_PROCEED_TO_PAYMENT: "click_proceed_to_payment",
  PAYMENT_STATUS: "payment_status",
  CLICK_PAYMENT_TRY_AGAIN: "click_payment_try_again",

  // MOTOR STEP ONE
  CLICK_CARS_FIELD: "click_cars_field",
  SELECT_CAR: "selected_car",
  QE_CAR_STEP1_VIEWED: "qe_car_step1_viewed",
  CAR_BRAND_CLICK_NEXT_BUTTON: "car_brand_click_next_button",
  CLICK_X_BUTTON: "click_x_button",
  SELECT_CAR_TYPE: "type_of_car",

  //MOTOR STEP TWO
  CLICK_CARS_MODEL_FIELD: "click_cars_model_field",
  SELECTED_CAR_MODEL: "selected_car_model",
  QE_CAR_STEP2_VIEWED: "qe_car_step2_viewed",
  CAR_MODEL_CLICK_NEXT_BUTTON: "car_model_click_next_button",

  //MOTOR STEP THREE
  CLICK_MANUFACTURE_YEAR_FIELD: "click_manufacture_year_field",
  SELECTED_MANUFACTURE_YEAR: "selected_manufacture_year",
  CAR_MANUFACTURE_CLICK_NEXT_BUTTON: "car_manufacture_click_next_button",
  QE_CAR_STEP3_VIEWED: "qe_car_step3_viewed",
  //MOTOR STEP FOUR
  QE_CAR_STEP4_VIEWED: "qe_car_step4_viewed",
  CLICK_CAR_PRICE_FIELD: "click_car_price_field",
  CAR_PRICE_CLICK_NEXT_BUTTON: "car_price_click_next_button",
  // MOTOR STEP FIVE
  QE_CAR_STEP5_VIEWED: "qe_car_step5_viewed",
  //MOTOR STEP SIX
  QE_CAR_STEP6_VIEWED: "qe_car_step6_viewed",
  //MOTOR STEP SEVEN
  QE_CAR_STEP7_VIEWED: "qe_car_step7_viewed",
  //Motor Step 8 - loading policies
  QE_CAR_STEP8_VIEWED: "qe_car_step8_viewed",
  CLICK_INFO_ICON: "click_info_icon",
  SELECT_USA_COVERAGE: "select_usa_coverage",
  QE_INTERNATIONAL_MEDICAL_STEP1_VIEWED: "qe_international_medical_step1_viewed",
  QE_INTERNATIONAL_MEDICAL_STEP2_VIEWED: "qe_international_medical_step2_viewed",
  QE_INTERNATIONAL_MEDICAL_STEP3_VIEWED: "qe_international_medical_step3_viewed",
  QE_INTERNATIONAL_MEDICAL_STEP4_VIEWED: "qe_international_medical_step4_viewed",
  QE_INTERNATIONAL_MEDICAL_STEP5_VIEWED: "qe_international_medical_step5_viewed",
  QE_INTERNATIONAL_MEDICAL_STEP6_VIEWED: "qe_international_medical_step6_viewed",
  REQUEST_CALL: "request_call",
  REQUEST_WHATSAPP: "request_whatsapp",
  THANK_YOU_REQUEST_CALL: "thank_you_request_call",
  QE_SME_STEP1_VIEWED: "qe_sme_step1_viewed",
  QE_SME_STEP2_VIEWED: "qe_sme_step2_viewed",
  QE_SME_STEP3_VIEWED: "qe_sme_step3_viewed",
  QE_SME_STEP4_VIEWED: "qe_sme_step4_viewed",
  QE_SME_STEP5_VIEWED: "qe_sme_step5_viewed",
  QE_SME_STEP6_VIEWED: "qe_sme_step6_viewed",
  COMPANY_CLICK_NEXT: "company_click_next",
  TOTAL_EMPLOYEE_CLICK_NEXT: "total_employee_click_next",
  SELECT_AVERAGE_EMPLOYEE_AGE: "selected_average_employee",
  CLICK_AVERAGE_EMPLOYEE_AGE_FIELD: "click_average_employee",
  //HOME STEP ONE
  TENANT_TYPE: "tenant_type",
  CLICK_GOVERNMENT_FIELD: "click_government_field",
  SELECT_GOVERNORATE: "selected_governorate",
  CLICK_CITY_FIELD: "click_city_field",
  SELECT_CITY: "selected_city",
  CLICK_HOME_VALUE_FIELD: "click_home_value_field",
  HOME_VALUE_CLICK_NEXT: "home_value_click_next",
  QE_HOME_STEP1_VIEWED: "qe_home_step1_viewed",
  QE_HOME_STEP2_VIEWED: "qe_home_step2_viewed",
  QE_HOME_STEP3_VIEWED: "qe_home_step3_viewed",
  QE_HOME_STEP4_VIEWED: "qe_home_step4_viewed",
  QE_HOME_STEP5_VIEWED: "qe_home_step5_viewed",
  QE_HOME_STEP6_VIEWED: "qe_home_step6_viewed",
  QE_HOME_STEP7_VIEWED: "qe_home_step7_viewed",
  QE_HOME_STEP8_VIEWED: "qe_home_step8_viewed"
}
export const usaCoverageEnum = {
  True: true,
  False: false,
}
export const carTypeEnum = {
  Electric: "electric",
  Fuel: "fuel"
}
export const pageActions = {
  SEE_MORE: "SEE_MORE",
  REQUEST_CALL: "REQUEST_CALL",
  WHATSAPP_SUPPORT: "WHATSAPP_SUPPORT"
}
export const linkFieldValue = "BUTTON"
export const pageSize = 10
export const hideBanner = "is_banner=false"
export const staticPageSize = 1000
